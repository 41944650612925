@import "~bootstrap/scss/bootstrap.scss";

// index.scss start

html,
body,
#root,
.app {
  min-height: 100%; /* fix IE11 */
}

@font-face {
  font-family: "NotoSans TC";
  font-style: normal;
  src: url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.otf)
      format("opentype");
}

body {
  margin: 0;
  font-family: "NotoSans TC", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// index.scss end
