footer {
  background: #f6f6f6;
  color: #333;
  font-size: 14px;
  line-height: 25px;
  padding: 30px 0;
  text-align: center;
  .companyName {
    font-weight: 600;
  }
  .phone {
    margin-right: 10px;
    @media (max-width: 768px) {
      display: block;
      margin: 0;
    }
  }
}
