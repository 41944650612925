// project
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  letter-spacing: 0.05em;
}
.page {
  // height: 100%;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  background-size: cover;
  padding: 90px 40px 210px;
  background-color: #eef8fa;
  background-repeat: no-repeat;
  background-position: center 100%;
  background-image: url("./../assets/images/bg.png");
  @media (max-width: 768px) {
    padding-bottom: 160px;
    background-color: #ebf6f9;
    background-image: url("./../assets/images/bg_mobile.png");
  }

  .logo {
    height: 115px;
    width: 115px;
    display: block;
    margin: 0 auto 68px;
    background-image: url("./../assets/images/logo.svg");
    background-repeat: no-repeat;
    text-indent: 101%;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width: 768px) {
      width: 62px;
      height: 88px;
      margin: 0 auto 49px;
      background-size: 90%;
      background-position: center 0;
      font-weight: 500;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    max-width: 582px;
    margin: 0 auto 30px;
    color: #666;
    text-align: justify;
  }
}
